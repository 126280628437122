.overlay-image {
  width: inherit;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  border-radius: inherit;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.overlay-image:hover {
  opacity: 0.9;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range .overlay-image-detail {
  background-color: #2c7d92;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range .overlay-image-detail {
  background-color: #2c7d92;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #2c7d92;
}

.greenn .CircularProgressbar-path {
  stroke: #2c7d92;
}
.greenn .CircularProgressbar-trail {
  stroke: #2c7d92;
  opacity: 0.2;
}

.reeed .CircularProgressbar-path {
  stroke: #f15a5b;
}
.reeed .CircularProgressbar-trail {
  stroke: #f15a5b;
  opacity: 0.2;
}

.overlay-image-detail {
  position: relative;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.overlay-image .overlay-image-detail {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-image-btn-edit {
  color: #ffff;
  border: #fff solid;
  border-radius: 24px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.overlay-image-btn-edit:hover {
  color: #ffff;
}

.overlay-image-btn-del,
.overlay-image-btn-plus {
  color: #ffff;
  padding: 5px;
  border-radius: 50%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-image-btn-del > svg,
.overlay-image-btn-plus > svg {
  margin: 0;
}

.overlay-image-btn-del:hover,
.overlay-image-btn-plus:hover {
  color: #ffff;
}

.overlay-image-btn-del {
  background-color: #f15a5b;
}

.overlay-image-btn-plus {
  background-color: #2c7d92;
}

.overlay-image a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.editor {
  border: 1px solid #ededed;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 0 24px;
  min-height: 15em;
  cursor: text;
  background: #ffffff;
}

.editor span {
  text-align: unset;
  position: unset;
  font-weight: unset;
  font-size: unset;
  transform: unset;
}
.editor .rdw-editor-toolbar {
  margin: 0 -24px;
  border-radius: 24px 24px 0 0;
}

.editor .rdw-link-modal {
  width: 400px;
  height: 250px;
}

.editor .rdw-dropdown-selectedtext {
  position: relative;
  outline: none;
}

.editor .rdw-list-dropdown {
  z-index: 10;
}

.editor .rdw-text-align-dropdown {
  z-index: 10;
}

.editor .rdw-link-modal-target-option {
  display: none;
}

.editor a {
  color: #000;
}

.modal {
  z-index: 1001 !important;
}
.modal-backdrop {
  z-index: 1000 !important;
}
.pac-container {
  z-index: 1055 !important;
}

.pac-card {
  margin: 10px 10px 0 0;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.controls {
  margin-top: 16px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  width: 75%;
}

.table tr {
  border-top: hidden;
}

div.active {
  border: 1px solid #2fae55;
}

.tagspotit {
  float: right;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1000;
  background-color: #2fae55;
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
}

sub {
  bottom: unset;
}

.notice {
  display: flex;
}

.dash-tab-containt {
  display: block;
}

.dash-tab-ri-jion-time {
  display: block;
}

.input-number {
  position: relative;
  width: 100%;
  height: 36px;
  overflow: hidden;
  border-radius: 24px;
  margin: 2px;
  border: 1px solid #ededed;
  background-color: white;
}

.input-number input {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  display: inline-block;
  height: 100%;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -1px;
  border: none;
}

.input-number button {
  position: absolute;
  display: inline-block;
  height: 19.5px;
  top: calc(40% - 19.5px / 2 + 0px);
  border: none;
  background: transparent;
}

.input-number > .input-number-plus {
  right: 8.25px;
}

.input-number > .input-number-minus {
  left: 8.25px;
}

.input-number button:disabled {
  filter: grayscale(100%);
}

.upload__ig {
  height: unset;
  min-height: 225px;
}

.cover-show {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.cover-show .cover-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 24px;
}
.cover-show:hover .cover-overlay {
  opacity: 0.9;
}

.cover-detail {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.cover-show:hover .cover-detail {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.fadeIn-top {
  top: 50%;
}

.cover-edit-button {
  color: #ffff;
  border: #fff solid;
  border-radius: 24px;
}

.cover-edit-button:hover {
  color: #ffff;
}

.cover-preview {
  border: none;
}

button > svg {
  margin-right: 5px;
}

.invalid-drop-zone {
  border-color: #ff0000 !important;
}

.white-star {
  color: #ededed;
}

.overlay {
  transition: 0.3ms !important;
}

.overlay > .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.overlay.active.isLoading {
  z-index: 1500;
  transition: 0.1ms;
}

.result__number a {
  color: unset;
}

.progress--fst span svg {
  color: #ffcf50;
}

.task__number.active span {
  background: #2c7d92;
  color: #fff;
  position: relative;
}

.task__number span:after {
  position: absolute;
  width: 1px;
  height: 48px;
  background: #2c7d92;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  top: -46px;
}

.task__number.first-task span:after {
  position: absolute;
  width: none;
  height: 48px;
  background: none;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  top: -46px;
}
.result__number ul li:nth-child(2) {
  background-color: unset;
}

.result__number ul li.active {
  background-color: #eaf2f4;
}

.test__top[aria-expanded='true'] .label-icon a {
  background: #c2c2cc;
}

.field-icon.is-invalid,
.single-input span.is-invalid {
  position: absolute;
  right: 30px;
  top: 18%;
  transform: none;
}

.css-14el2xx-placeholder {
  padding-left: 10px;
}

.progress {
  border-radius: 10px;
  height: 10px;
  overflow: hidden;
  margin: 5px 0;
}
.progress-bar {
  background-color: #2c7d92;
}

.btn-media-types {
  background-color: #f7f8fa;
  border: 1px solid #f7f8fa;
  border-radius: 10px;
  font-size: 12px;
  color: #7d7d7d;
  padding: 10px 20px;
  display: flex;
}
.btn-media-types.active {
  border-color: #2c7d92;
  color: #2c7d92;
}
.btn-media-types:before {
  content: '';
  width: 17px;
  height: 17px;
  background: no-repeat center;
  background-size: contain;
  margin-right: 10px;
}
.btn-media-types:focus {
  box-shadow: none;
}
.btn-media-types.btn-banner:before {
  background-image: url('./../images/icon-banner.svg');
}
.btn-media-types.btn-banner.active:before {
  background-image: url('./../images/icon-banner-active.svg');
}
.btn-media-types.btn-full-image:before {
  background-image: url('./../images/icon-full-image.svg');
}
.btn-media-types.btn-full-image.active:before {
  background-image: url('./../images/icon-full-image-active.svg');
}

.upload-wrapper {
  width: 70%;
}
.upload-container {
  border-radius: 20px;
  border: 1px dashed #2c7d92;
  background-color: #f7f8fa;
  text-align: center;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.upload-container.banner-upload {
  margin-right: 50px;
}
.upload-container .preview-container img {
  object-fit: cover;
  max-width: 100%;
}
.upload-container .preview-container {
  display: flex;
  justify-content: center;
}

.upload-container.banner-upload .preview-container {
  max-height: 150px;
}
.upload-container.full-image-upload .preview-container {
  height: 300px;
}
.upload-container.full-image-upload .preview-container img {
  max-width: none;
  max-height: 100%;
}
.upload-container.ar-trigger-image {
  width: 200px;
  height: 200px;
}

.upload-container.ar-image {
  height: 300px;
  width: 300px;
}
.ar-upload-title {
  width: 300px;
  margin-top: 20px;
}
.ar-upload-title input {
  width: 100%;
}

.ar-image-upload {
  margin-bottom: 0;
}

.uploader {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-container.full-image-upload .uploader {
  height: 300px;
}

.upload-container .upload-text .upload-text-title {
  color: #111;
}

.icon-remove {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  z-index: 99;
}

.banner-upload .icon-remove {
  right: -50px;
  top: 70px;
}

.btn-add-file {
  color: #2c7d92;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  display: flex;
  padding: 8px 14px;
}
.btn-add-file:focus {
  box-shadow: none;
}
.augmented .upload-text {
  margin-top: 15px;
  font-size: 13px;
}
.augmented .upload-text-title {
  color: inherit;
  font-weight: 500;
}
.augmented .upload-text-title strong {
  color: #2c7d92;
}

.view-task-type.scan-qr .view-task-type-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-task-type.scan-qr .view-task-answer,
.view-task-type.scan-qr p {
  margin-bottom: 0;
}
.view-task-type-detail {
  margin-bottom: 30px;
}
.view-task-type-detail > div {
  margin-bottom: 20px;
}
.view-task-type-detail > div > label {
  font-size: 14px;
  margin-bottom: 10px;
}
.view-task-type-detail > div > p {
  font-size: 18px;
  color: #333;
}
.view-task-type-detail > .view-task-answer.polling-answer {
  margin-bottom: 50px;
}
.view-task-type-detail .polling-answer > label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.polling-answer label span {
  font-weight: bold;
  color: #333;
}
.polling-results {
  width: 100%;
}
.polling-result {
  margin-bottom: 20px;
}
.polling-result label {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 5px;
}
.polling-progress {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
}
.polling-progress .progress {
  width: 100%;
}
.polling-number {
  width: 75px;
  text-align: right;
}
.view-task-type-detail > .view-task-answer.multiple-choices-answer {
  margin-bottom: 50px;
}
.answer-choices .answer-choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin: 15px 0;
}
.answer-choices .answer-choice .answer-choice-value {
  padding: 10px 25px;
  background-color: #f7f8fa;
  width: 100%;
  border-radius: 30px;
}
.answer-choices .answer-choice.correct-answer .answer-choice-value {
  background: rgba(47, 174, 85, 0.2);
}
.answer-choices .answer-choice .answer-choice-note {
  width: 150px;
  white-space: nowrap;
}
.answer-choices .answer-choice .answer-choice-note img {
  margin-right: 5px;
}

.view-task-type-detail.spot-it .spot-it-grid {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.view-task-type-detail.spot-it .spot-it-grid img {
  position: absolute;
}
.view-task-type-detail.spot-it .spot-it-grid .grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  z-index: 99;
  gap: 0;
  position: relative;
}
.view-task-type-detail.spot-it .spot-it-grid .grid .grid-item {
  height: 120px;
  border: 1px solid #fff;
}
.view-task-type-detail.spot-it .spot-it-grid .grid .grid-item.active {
  border: 3px solid #2fae55;
}
.view__task__top--img.x-scrollable {
  overflow-x: scroll;
  padding-bottom: 20px;
  width: 100%;
}
.view__task__top--img.x-scrollable::-webkit-scrollbar {
  height: 10px;
}
.view__task__top--img.x-scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.view__task__top--img.x-scrollable::-webkit-scrollbar-thumb {
  background: #2c7d92;
  border-radius: 10px;
}
.view__task__top--img.x-scrollable .scroll-container {
  width: max-content;
}
.view__task__top--img.x-scrollable .img-wrapper {
  overflow: hidden;
  width: 320px;
  height: 400px;
  border-radius: 15px;
  margin-right: 20px;
  float: left;
}
.view__task__top--img.x-scrollable .img-wrapper img {
  object-fit: cover;
}
.view-task-answer.box-answer {
  margin-bottom: 50px;
  overflow: hidden;
}
.box-answer .box-answer-boxes {
  padding: 10px 0;
}
.box-answer .box-answer-boxes .ba-box {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  color: #222;
  width: 40px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  border-radius: 7px;
  margin-right: 7px;
  float: left;
}
.box-answer .box-answer-boxes .ba-box.space {
  background-color: transparent;
  width: 10px;
}

.track-area.qt5.hpa .track-left {
  background: #fff8e5;
  border-radius: 22px;
}

.containerk p span.active {
  background: #ffcf50;
  color: #000;
}

.__react_component_tooltip {
  max-width: 15rem;
  text-align: center;
  border-radius: 10px !important;
  font-size: 14px !important;
}

.brightness-90 {
  filter: brightness(90%);
}

.radio-toolbar label.brightness-90:hover {
  background-color: #f7f8fa;
}
